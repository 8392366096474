<script>
import SiteNew from './SiteNewView'
import NotifyService from '../../services/NotifyService'

export default {
  name: 'SiteEditView',
  extends: SiteNew,
  methods: {
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
        return
      }
      this.$store.dispatch('site/update', this.site)
        .then(() => {
          this.site = this.$store.getters['site/detail']
          if (this.$store.getters['site/error'] === null) {
            if (this.conflictData && this.conflictData.length > 0) {
              const rubricsInError = this.conflictData.reduce((acc, rubricInError) => {
                const siteTitle = this.$store.getters['site/getTitleById'](rubricInError.siteId)
                const message = `<li>${this.$t('rubric.site')}: <b>${siteTitle}</b>, ${this.$t('rubric.rubricId')}: <b>${rubricInError.rubricId}</b>, ${this.$t('rubric.rubricTitle')}: <b>${rubricInError.title}</b></li>`
                return `${acc}${message}`
              }, '')

              const formattedMessage = `<ul>${rubricsInError}</ul>`
              NotifyService.setErrorMessage(`<b>${this.$t('site.conflictData')}</b>`, formattedMessage, 10000)
            } else {
              NotifyService.setSuccessMessage(this.$t('notify.record_was_updated'))
            }
          } else {
            NotifyService.setErrorMessage(this.$store.getters['site/error'])
          }
        })
        .catch(error => console.log(error))
    }
  },
  async created () {
    await this.$store.dispatch('site/fetchOne', this.$route.params.id)
    this.site = this.$store.getters['site/detail']
  }
}
</script>
